<template>
  <div>
    <a-row class="mt-5 mb-3 d-flex align-items-center">
      <a-col :span="8" class="d-flex justify-content-start align-items-center">
        <div
          style="
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #1A1A1A;
          "
          class="d-flex justify-content-center align-items-center"
        >
          {{ $t('fulfillment.itemProduct') }}
        </div>
      </a-col>

      <a-col v-if="showListItemToUpdate" :span="16" class="d-flex justify-content-end align-items-center">
        <div
          class="d-flex justify-content-center align-items-center mx-2"
          style="cursor: pointer"
          @click.prevent="handleButtonBack"
        >
          <arrow-icon class="mx-2" style="color: #E00000" :rotate="'rotate(270)'" />
          <div class="mx-2 pbz-font buttom-md-medium" style="color: #141414">
            Kembali
          </div>
        </div>

        <div
          v-if="!showAllProductAvailable"
          class="d-flex justify-content-center align-items-center mx-2"
          style="cursor: pointer"
          @click.prevent="() => (
            showAllProductAvailable = true
          )"
        >
          <plus-icon />
          <div class="mx-2 pbz-font buttom-md-medium" style="color: #141414">
            Tambah Produk
          </div>
        </div>
      </a-col>
    </a-row>

    <template v-if="!showAllProductAvailable">
      <ShowListItemComponent
        v-if="showListItem"
        :products="products"
        @handleItemCheckbox="handleItemCheckbox"
      />

      <a-row v-if="showListItemToUpdate" class="product" :style="{ overflowY: productsToUpdate.length >=5 && 'scroll'}">
        <div v-for="(item, index) in productsToUpdate" :key="index" style="cursor: pointer">
          <a-row class="product-item d-flex align-items-center">
            <a-col :span="3" class="d-flex justify-content-center align-items-center">
              <div class="image d-flex justify-content-center align-items-center">
                <img height="35" width="35" :src="item.img_url ? item.img_url : 'https://ik.imagekit.io/powerbiz/no-image.png?ik-sdk-version=javascript-1.4.3&updatedAt=1603265244531'" alt="img" />
              </div>
            </a-col>

            <a-col :span="4">
              <div class="pbz-font button-sm-medium product-item__title">
                {{ item.catalog_title }}
              </div>
              <div class="pbz-font caption-medium product-item__sku">
                SKU: {{ item.sku }}
              </div>
            </a-col>

            <a-col :span="2" class="d-flex justify-content-center align-items-center">
              <a-dropdown v-model="item.show_all_products" overlay-class-name="dropdown-update-fulfillment" :trigger="['click']" placement="bottomRight">
                <div style="cursor: pointer" class="ant-dropdown-link" @click.prevent="handleOpenAllProduct(item.catalog_id, !item.show_all_products)">
                  <ArrowIcon
                    style="color: #E00000"
                    :rotate="'rotate(180)'"
                  />
                </div>
                <a-menu slot="overlay" class="list-available" @click="(e) => handleMenuClick(e, item.catalog_id, index)">
                  <a-menu-item key="search-box">
                    <div class="search-box">
                      <SearchIcon class="i" style="color: #999999" />
                      <input class="field" type="text" placeholder="cari item" @input="handleSearch" />
                    </div>
                  </a-menu-item>

                  <a-menu-item v-for="(value, indexAllProducts) in all_products.slice(0,limit_catalog)" :key="indexAllProducts">
                    <a-row class="item-all-product">
                      <a-col :span="4" class="px-2 py-2 d-flex justify-content-start align-items-center">
                        <div class="image d-flex justify-content-center align-items-center">
                          <img height="35" width="35" :src="value.showimg_url ? value.showimg_url : 'https://ik.imagekit.io/powerbiz/no-image.png?ik-sdk-version=javascript-1.4.3&updatedAt=1603265244531'" />
                        </div>
                      </a-col>

                      <a-col :span="20" class="px-2 py-2">
                        <div class="title">
                          {{ value.title }}
                        </div>
                        <div class="sku">
                          SKU: {{ value.sku }}
                        </div>
                      </a-col>
                    </a-row>
                  </a-menu-item>

                  <a-menu-item v-if="utils_all_products.showButtonLoadMore" key="load-more">
                    <a-row>
                      <a-col :span="24" class="mt-3">
                        <div
                          class="pbz-font button-sm-medium d-flex justify-content-center align-items-center"
                          style="
                            border: 1px solid #E00000;
                            background: #E00000;
                            color: #FFFFFF;
                            width: 100%;
                            padding: .5rem;
                            border-radius: 5px;
                            cursor: pointer;
                          "
                        >
                          <div v-if="!utils_all_products.loading">
                            Load More
                          </div>
                          <div v-if="utils_all_products.loading">
                            <a-icon type="loading" /> Loading
                          </div>
                        </div>
                      </a-col>
                    </a-row>
                  </a-menu-item>
                </a-menu>
              </a-dropdown>
            </a-col>

            <a-col :span="3" class="d-flex justify-content-center align-items-center flex-column">
              <div class="product-item__sku">
                Ubah Qty
              </div>
              <a-input-number
                v-model="item.quantity_changed"
                size="small"
                :min="1"
                :default-value="1"
                @change="(value) => handleChangeQuantity(value, item.id)"
              />
            </a-col>

            <a-col :span="6" class="d-flex justify-content-center align-items-center flex-column">
              <div class="product-item__sku">
                Inventori Booking
              </div>
              <div class="product-item__title">
                {{ wordingBookingState(item.booking_state) }}
              </div>
            </a-col>

            <a-col :span="5" class="d-flex justify-content-center align-items-center">
              <div :style="styleOrderUpdated(item.status_updated)" class="product-item__title mx-1">
                {{ item.status_updated }}
              </div>

              <div v-if="!item.id" class="mx-1" @click="handleDeleteUpdateItem(item.catalog_id)">
                <trash-icon
                  style="color: #E00000"
                />
              </div>
            </a-col>
          </a-row>
        </div>
      </a-row>

      <a-row class="footer-update-order mt-5">
        <a-col :span="12" class="d-flex justify-content-center align-items-center">
          <div class="cancel pbz-font button-sm-medium" @click.prevent="() => $emit('handleCancel')">
            Batal
          </div>
        </a-col>
        <a-col :span="12" class="d-flex justify-content-center align-items-center">
          <div
            class="pbz-font button-sm-medium d-flex justify-content-center align-items-center"
            :class="{
              ['save']: disabledButtonFooter ? false : true,
              ['disabled-save']: disabledButtonFooter ? true : false,
            }"
            @click.prevent="handleSubmitButtonFooter"
          >
            Selanjutnya
          </div>
        </a-col>
      </a-row>
    </template>

    <template v-if="showAllProductAvailable">
      <AllProductsAvailableComponent
        :all-products="all_products_raw"
        @handleCancel="() => $emit('handleCancel')"
        @handleSubmitAddItem="handleSubmitAddItem"
      />
    </template>
  </div>
</template>

<script>
import ArrowIcon from '@/components/Icons/Arrow.vue'
import PlusIcon from '@/components/Icons/PlusIcon.vue'
import TrashIcon from '@/components/Icons/TrashIcon.vue'
import ShowListItemComponent from '../ShowListItem'
import SearchIcon from '@/components/Icons/Search.vue'
import { setWordingBookingState } from '@/utils/fulfillment/index'

import debounce from 'lodash/debounce'
import { getCatalogList } from '@/api/product'

import AllProductsAvailableComponent from './all_products.vue'

export default {
  components: {
    SearchIcon,
    ArrowIcon,
    ShowListItemComponent,
    PlusIcon,
    AllProductsAvailableComponent,
    TrashIcon,
  },
  props: {
    propsProducts: {
      type: Array,
      default: () => [],
    },
    order: {
      type: Object,
      default: () => {},
    },
  },
  data () {
    return {
      products: [],
      productsToUpdate: [],
      showListItem: true,
      showListItemToUpdate: false,
      disabledButtonFooter: true,
      all_products: [],
      all_products_raw: [],
      utils_all_products: {
        page: 1,
        limit: 10000,
        q: undefined,
        showButtonLoadMore: true,
        loading: false,
      },
      showAllProductAvailable: false,
      limit_catalog: 100,
      total_row_catalog: 0,
    }
  },
  watch: {
    propsProducts: {
      immediate: true,
      deep: true,
      handler: function () {
        this.products = this.propsProducts
      },
    },
    products: {
      immediate: true,
      deep: true,
      handler: function (value) {
        let itemChecked = 0

        value.forEach((item) => {
          if (item.item_checked) {
            itemChecked++
          }
        })

        // eslint-disable-next-line
        this.disabledButtonFooter = itemChecked > 0 ? false : true

        this.productsToUpdate = Array.isArray(value) && value.length > 0 ? value.filter(f => f.item_checked).map(item => ({
          ...item,
          status_updated: '',
          show_all_products: false,
          quantity_changed: item.quantity,
          is_quantity_change: false,
          is_line_item_change: false,
        })) : []
      },
    },
    productsToUpdate: {
      immediate: true,
      deep: true,
      handler: function (value) {
        value.forEach((item, index) => {
          if ((item.is_quantity_change) || (item.is_line_item_change)) {
            this.productsToUpdate[index].status_updated = 'Ubah Item'
          } else {
            this.productsToUpdate[index].status_updated = ''
          }
        })
      },
    },
  },
  mounted() {
    this.fetchAllProduct()
  },
  methods: {
    async fetchAllProduct () {
      this.utils_all_products.loading = true
      await getCatalogList({
        page: this.utils_all_products.page,
        limit: this.utils_all_products.limit,
        q: this.utils_all_products.q,
        business_id: this.order.business_id,
      })
      .then(({ data: { data: response, total_row } }) => {
        // if (this.utils_all_products.q) {
        //   this.all_products = data.map(item => ({
        //     ...item,
        //     showimg_url: item.image_url && Array.isArray(item.image_url) ? item.image_url[0].imagePath || item.image_url[0] : null,
        //   }))
        // } else {
          this.total_row_catalog = total_row
          this.all_products = [...this.all_products, ...response]

          this.all_products = this.all_products.map(item => ({
            ...item,
            showimg_url: item.image_url && Array.isArray(item.image_url) ? item.image_url[0]?.imagePath || item.image_url[0] : null,
          }))
          this.all_products_raw = [...this.all_products_raw, ...response]

          this.all_products_raw = this.all_products_raw.map(item => ({
            ...item,
            showimg_url: item.image_url && Array.isArray(item.image_url) ? item.image_url[0]?.imagePath || item.image_url[0] : null,
          }))
        // }

        // if (this.all_products.length >= total_row) {
        //   this.utils_all_products.showButtonLoadMore = false
        // }
          if (this.limit_catalog >= total_row) {
          this.utils_all_products.showButtonLoadMore = false
        }
      })
      .catch(err => console.error(err))
      .finally(() => {
        this.utils_all_products.loading = false
      })
    },
    handleItemCheckbox ({ event, id }) {
      this.products = this.products.map(item => ({
        ...item,
        item_checked: item.id === id ? event.target.checked : item.item_checked,
      }))
    },
    handleSubmitButtonFooter () {
      if (this.disabledButtonFooter) {
        // eslint-disable-next-line
        return
      }
      if (this.showListItem) {
        this.showListItem = false
        this.showListItemToUpdate = true

        // eslint-disable-next-line
        return
      }

      const payload = this.productsToUpdate.map(item => ({
        id: item.id,
        catalog_id: item.catalog_id,
        catalog_title: item.catalog_title,
        sku: item.sku,
        quantity: item.quantity === item.quantity_changed ? item.quantity : item.quantity_changed,
        uom: item.uom,
        img_url: item.img_url,
        is_line_item_change: item.is_line_item_change,
        is_quantity_change: item.is_quantity_change,
      }))

      this.$emit('setPayloadUpdateItem', payload)
      this.$emit('handleModalConfirmation', true)
    },
    handleOpenAllProduct() {
      // this.productsToUpdate = this.productsToUpdate.map(item => ({
      //   ...item,
      //   show_all_products: item.catalog_id === catalog_id ? state : false,
      // }))

      // if (state) {
      //   this.fetchAllProduct()
      // }
    },
    handleMenuClick (event, catalog_id, paramIndex) {
      if (event.key === 'search-box' || event.key === 'load-more') {
        this.productsToUpdate = this.productsToUpdate.map(item => ({
          ...item,
          show_all_products: item.catalog_id === catalog_id ? true : item.show_all_products,
        }))

        if (event.key === 'load-more') {
          // this.utils_all_products.page = this.utils_all_products.page + 1
          this.limit_catalog = this.limit_catalog + 100
          if (this.limit_catalog >= this.total_row_catalog) {
            this.utils_all_products.showButtonLoadMore = false
          }
          // this.fetchAllProduct()
        }
      }

      const product = this.all_products.find((_, index) => index === event.key)

      // this.productsToUpdate = this.productsToUpdate.map((item, index) => {
      //   if (item.catalog_id === catalog_id) {
      //     return {
      //       booking_state: null,
      //       catalog_id: product.id,
      //       catalog_title: product.title,
      //       id: null,
      //       img_url: product.showimg_url,
      //       is_line_item_change: true,
      //       is_quantity_change: false,
      //       item_checked: false,
      //       order_line_item_id: null,
      //       quantity: item.quantity,
      //       quantity_changed: item.quantity_changed,
      //       selected_to_cancel: false,
      //       show_all_products: false,
      //       sku: product.sku,
      //       status_updated: '',
      //       uom: product.uom || 'pcs',
      //     }
      //   } else {
      //     return item
      //   }
      // })
      this.productsToUpdate = this.productsToUpdate.map((item, index) => {
        if (index === paramIndex) {
          return {
            booking_state: null,
            catalog_id: product.id,
            catalog_title: product.title,
            id: item.id,
            img_url: product.showimg_url,
            is_line_item_change: true,
            is_quantity_change: false,
            item_checked: false,
            order_line_item_id: null,
            quantity: item.quantity,
            quantity_changed: item.quantity_changed,
            selected_to_cancel: false,
            show_all_products: false,
            sku: product.sku,
            status_updated: '',
            uom: product.uom || 'pcs',
          }
        } else {
          return item
        }
      })
    },
    handleChangeQuantity (value, id) {
      this.productsToUpdate = this.productsToUpdate.map((item) => ({
        ...item,
        quantity_changed: (item.catalog_id === id) ? value : item.quantity_changed,
        is_quantity_change: (item.catalog_id === id) && (item.quantity !== value) ? true : (item.catalog_id === id) && (item.quantity === value) ? false : item.is_quantity_change,
      }))
    },
    wordingBookingState: function (value) {
      return setWordingBookingState(value)
    },
    styleOrderUpdated (status) {
      const data = status.toLowerCase()

      const style = {
        boxShadow: '0px 5px 5.3625px rgba(0, 0, 0, 0.055)',
        borderRadius: '33px',
        color: '#FFFFFF',
        padding: '4px 12px',
      }

      switch (data) {
        case 'pengiriman terpisah': {
          return {
            ...style,
            background: '#454655',
          }
        }

        case 'batal': {
          return {
            ...style,
            background: '#E00000',
          }
        }

        case 'ubah item': {
          return {
            ...style,
            background: '#454655',
          }
        }

        default: {
          return {}
        }
      }
    },
    handleSearch: debounce(function (e) {
      const q = e.target.value

      this.all_products = this.all_products_raw.filter((item) => {
        if (item.title !== null) {
          return item.title.toLowerCase().indexOf(q.toLowerCase()) >= 0
        }
      })

      if (!q.length && this.all_products_raw.length) {
        this.all_products = this.all_products_raw
      } 
    }, 500),
    handleButtonBack () {
      if (!this.showAllProductAvailable) {
        this.showListItem = true
        this.showListItemToUpdate = false

        // eslint-disable-next-line
        return
      }

      if (this.showAllProductAvailable) {
        this.showAllProductAvailable = false

        // eslint-disable-next-line
        return
      }
    },
    handleSubmitAddItem (payload = []) {
      const mappingData = payload.map(item => ({
        catalog_title: item.title,
        catalog_id: item.id,
        img_url: item.showimg_url,
        status_updated: '',
        show_all_products: false,
        quantity: 1,
        quantity_changed: 1,
        is_quantity_change: false,
        is_line_item_change: false,
        item_checked: false,
        sku: item.sku,
        uom: item.uom || 'PCS - HARDCODE',
        id: null,
      }))

      this.productsToUpdate = [...this.productsToUpdate, ...mappingData]
      this.showAllProductAvailable = false
    },
    handleDeleteUpdateItem (catalog_id) {
      this.productsToUpdate = this.productsToUpdate.filter(item => item.catalog_id !== catalog_id)
    },
  },
}
</script>

<style lang="scss">
.dropdown-update-fulfillment.ant-dropdown {
  width: 360px !important;
  max-height: 434px !important;
  overflow-x: hidden;

  .ant-dropdown-menu-item {
    height: 5rem;
  }

  .list-available {
    .search-box {
      width: 338px;
      position: fixed;
      z-index: 1;
    }
    .field {
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      width: 100%;
      text-align: left;
      padding-left: 2.5rem !important;
      padding: 10px 15px;
      height: 48px;
      background: #FFFFFF;
      border: 1px solid #CCCCCC;
      box-sizing: border-box;
      border-radius: 5px;
      color: #999999;
    }
    .search-box > .i {
      position: absolute;
      margin: 1rem 0 0 0.5rem;
    }
    .field::placeholder {
      color: #999999 !important;
    }

    .filter-box {
      width: 93px;
      height: 48px;
      padding: 2px;
      background: #FFFFFF;
      border: 1px solid #CCCCCC;
      box-sizing: border-box;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      div {
        margin: 0 5px;
      }

      .caption {
        font-family: 'Poppins', sans-serif !important;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        color: #999999;
      }
    }

    .image {
      border: 0.666667px solid #CCCCCC;
      box-sizing: border-box;
      border-radius: 1.66667px;
      width: 40px;
      height: 40px;
    }

    .title {
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #1A1A1A;
    }

    .sku {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: #999999;
    }
  }
}
</style>
