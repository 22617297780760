<template>
  <div>
    <a-modal
      :visible="showVisibleModal"
      class="update-item"
      :footer="false"
      :title="null"
      @cancel="handleCancel"
    >
      <div v-if="order && detailFulfillment && $route.query.actionUpdate">
        <a-row style="margin: 0 0 10px 0">
          <h3> {{ titleModalUpdateOrder }} </h3>
        </a-row>

        <a-row type="flex" justify="space-around" class="information">
          <a-col :span="6">
            <div class="title">
              {{ $t('utils.date') }}
            </div>
            <div class="value">
              {{ $moment(order.order.order_date).format('DD MMMM YYYY') }}
            </div>
          </a-col>
          <a-col :span="4" class="text-center">
            <div class="title">
              {{ $t('utils.hour') }}
            </div>
            <div class="value">
              {{ $moment(order.order.order_date).format('HH:mm') }}
            </div>
          </a-col>
          <a-col :span="6">
            <div class="title">
              {{ $t('utils.channel') }}
            </div>
            <div class="value">
              {{ order.order.channel.name }}
            </div>
          </a-col>
          <a-col :span="6" class="text-center">
            <div class="title">
              No. Order
            </div>
            <div class="value">
              {{ order.order.order_number }}
            </div>
          </a-col>
        </a-row>

        <template v-if="$route.query.actionUpdate === FulfillmentConstant.FULFILLMENT_CHANGE_ITEM_ACTION">
          <UpdateItemComponent
            :props-products="products"
            :order="order"
            @handleCancel="handleCancel"
            @setPayloadUpdateItem="setPayloadUpdateItem"
            @handleModalConfirmation="handleModalConfirmation"
          />
        </template>

        <template v-if="$route.query.actionUpdate === FulfillmentConstant.FULFILLMENT_SPLIT_SHIPMENT_ACTION">
          <UpdateSplitShipmentItemComponent
            :props-products="products"
            :order="order"
            :detail-fulfillment="detailFulfillment"
            @handleCancel="handleCancel"
            @setPayloadSplitShipment="setPayloadSplitShipment"
            @handleModalConfirmation="handleModalConfirmation"
          />
        </template>

        <template v-if="$route.query.actionUpdate === FulfillmentConstant.FULFILLMENT_CANCEL_ITEM_ACTION">
          <CancelItemComponent
            :props-products="products"
            @setPayloadCancelItem="setPayloadCancelItem"
            @handleCancel="handleCancel"
            @handleModalConfirmation="handleModalConfirmation"
          />
        </template>
      </div>
    </a-modal>

    <ModalConfirmation
      v-if="showModalConfirmation"
      :order="order"
      :payload-split-shipment="payloadSplitShipment"
      :payload-cancel-item="payloadCancelItem"
      :payload-update-item="payloadUpdateItem"
      :detail-fulfillment="detailFulfillment"
      :products="products"
      @handleModalConfirmation="handleModalConfirmation"
      @handleCancelOneItem="handleCancelOneItem"
    />

    <ModalProcessingState
      :show-modal="modalInformationProcessingState.show"
      :modal-information-processing-state="modalInformationProcessingState"
      :force-to-reason-cancel-item="forceToReasonCancelItem"
      @setModalInformationProcessingState="setModalInformationProcessingState"
    />
  </div>
</template>

<script>
import ModalConfirmation from './ModalConfirmation'
import {
  urlToDetailFulfillment,
} from '@/utils/fulfillment/index'
import FulfillmentConstant from '@/constants/fulfillment'

import UpdateItemComponent from './UpdateItem'
import UpdateSplitShipmentItemComponent from './UpdateSplitShipment'
import CancelItemComponent from './CancelItem'

import ModalProcessingState from '@/components/Fulfillment/ModalProcessingState/index.vue'

export default {
  components: {
    ModalConfirmation,
    UpdateItemComponent,
    UpdateSplitShipmentItemComponent,
    CancelItemComponent,
    ModalProcessingState,
  },
  props: {
    order: {
      type: Object,
    },
    detailFulfillment: {
      type: Object,
      default: () => ({} || []),
    },
  },
  data () {
    return {
      showVisibleModal: false,
      FulfillmentConstant,
      products: [],
      payloadSplitShipment: {},
      showModalConfirmation: false,
      payloadCancelItem: {},
      payloadUpdateItem: [],
      modalInformationProcessingState: {
        show: false,
        orderId: null,
        channelCode: null,
        fulfillmentId: null,
        state: null,
        action: null,
      },
    }
  },
  computed: {
    getUrlToDetailFulfillment: function () {
      return urlToDetailFulfillment({
        meta_key_url: this.$route.meta.key,
        id: this.$route.params.id,
        channel_type: this.$route.params.channelType,
      })
    },
    titleModalUpdateOrder () {
      switch (this.$route.query.actionUpdate) {
        case this.FulfillmentConstant.FULFILLMENT_SPLIT_SHIPMENT_ACTION:
          return 'Pengiriman Terpisah'

        case this.FulfillmentConstant.FULFILLMENT_CANCEL_ITEM_ACTION:
          return 'Batalkan Item'

        case this.FulfillmentConstant.FULFILLMENT_CHANGE_ITEM_ACTION:
          return 'Ubah Item'

        default:
          return null
      }
    },
    forceToReasonCancelItem () {
      if (this.payloadCancelItem && this.payloadCancelItem.line_items && (this.payloadCancelItem.line_items.length === 1)) {
        return true
      }

      return false
    },
  },
  watch: {
    detailFulfillment: {
      deep: true,
      immediate: true,
      handler: function (value) {
        if (value && (value.id === this.$route.query.fulfillment_id_to_update) && (Array.isArray(value.line_items) && value.line_items.length > 0)) {
          this.products = value.line_items.map(item => ({
            ...item,
            item_checked: false,
            selected_to_cancel: false,
          }))

          this.showVisibleModal = true
        } else {
          this.showVisibleModal = false
        }
      },
    },
  },
  methods: {
    handleCancel () {
      const yAxis = window.top.scrollY
      const query = { ...this.$route.query }
      delete query.actionUpdate
      delete query.fulfillment_id_to_update
      this.showVisibleModal = false
      this.$router.push({
        path: this.getUrlToDetailFulfillment,
        query,
      }).then(() => window.scrollTo(0, yAxis))
    },
    handleModalConfirmation (value) {
      this.showModalConfirmation = value

      if (value) {
        this.showVisibleModal = false
      } else {
        this.showVisibleModal = true
      }
    },
    setPayloadSplitShipment (value) {
      this.payloadSplitShipment = value
    },
    setPayloadCancelItem (value) {
      this.payloadCancelItem = value
    },
    setPayloadUpdateItem (value) {
      this.payloadUpdateItem = value
    },
    handleCancelOneItem (value) {
      if (value) {
        this.modalInformationProcessingState.show = true
        this.modalInformationProcessingState.fulfillmentId = this.$route.query.fulfillment_id_to_update
        this.modalInformationProcessingState.action = 'reject-order'
        this.modalInformationProcessingState.orderId = this.detailFulfillment.order.id
        this.modalInformationProcessingState.channelCode = this.detailFulfillment.order.channel.code
        this.showVisibleModal = false
      }
    },
    setModalInformationProcessingState (payload) {
      this.modalInformationProcessingState = payload
    },
  },
}
</script>

<style lang="scss">
.update-item {
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;

  .ant-modal {
    width: 781px !important;
    height: auto !important;

    @media (max-width: 576px) {
      width: auto !important;
      height: auto !important;
    }

    &-content {
      background: #FFFFFF !important;
      box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
      border-radius: 10px !important;
    }

    h3 {
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
      color: #1A1A1A;
    }
  }

  .information {
    .title {
      font-weight: normal;
      font-size: 12px;
      line-height: 18px;
      color: #999999;
    }

    .value {
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      color: #1A1A1A;
    }
  }

  .product {
    max-height: 400px !important;
    // overflow-y: hidden;

    &-item {
      height: auto;
      padding: 10px;

      .image {
        border: 0.666667px solid #CCCCCC;
        box-sizing: border-box;
        border-radius: 1.66667px;
        width: 40px;
        height: 40px;
      }

      &__title {
        color: #1A1A1A;
      }

      &__sku {
        color: #999999;
      }
    }

    &-divider {
      .ant-divider-horizontal {
        margin: 0px !important;
      }
    }
  }

  .footer-update-order {
    .cancel {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      color: #E00000;
      border: 1px solid #E00000;
      border-radius: 5px;
      padding: .75rem;
      cursor: pointer;
      margin-right: 5px;
    }

    .save {
      border: 1px solid #E00000;
      background: #E00000 !important;
      color: #FFFFFF;
      width: 100%;
      padding: .75rem;
      border-radius: 5px;
      cursor: pointer;
    }

    .disabled-save {
      border: 1px solid #CCCCCC;
      background: #CCCCCC !important;
      color: #FFFFFF;
      width: 100%;
      padding: .75rem;
      border-radius: 5px;
      cursor: not-allowed;
    }
  }

  .split-shipment-setup {
    padding: 15px 40px;
    height: auto;
    background: #F2F2F2;
    border-radius: 8px;
    margin: 10px 0px;

    .button-create-split-shipment {
      padding: 6px 10px;
      width: 88px;
      height: 33px;
      left: 573px;

      background: #0559CB;
      border-radius: 3px;
      color: #FFFFFF;

      cursor: pointer;
    }
  }

  .status {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 12px;
    width: fit-content;
    box-shadow: 0px 5px 5.3625px rgba(0, 0, 0, 0.055);
    border-radius: 33px;
    color: #ffffff;
  }

  .choice-split-shipment {
    padding: 5px !important;
  }

  .choice-split-shipment:hover {
    background: #E00000 !important;
    border-radius: 2px !important;

    div {
      color: #FFFFFF !important;
    }
  }
}
</style>
