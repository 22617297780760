<template>
  <div>
    <a-row>
      <a-col :span="24">
        <div class="search-box">
          <!-- <SearchIcon class="i" style="color: red !important" /> -->
          <input class="field" type="text" placeholder="cari item" @input="handleSearch">
        </div>
      </a-col>

      <a-col :span="24" class="d-flex align-items-center flex-wrap mt-2">
        <a-row class="show-product">
          <a-col
            v-for="(item, index) in products.slice(0,limit_catalog)"
            :key="index"
            class="my-2"
            :span="12"
            style="cursor: pointer"
            @click.prevent="handleItemCheckbox(!item.item_checked, item.id)"
          >
            <a-row class="d-flex justify-content-center align-items-center">
              <a-col :span="2" class="d-flex justify-content-center align-items-center">
                <a-checkbox :checked="item.item_checked" @change="(value) => handleItemCheckbox(value, item.id)" />
              </a-col>
              <a-col :span="6" class="d-flex justify-content-center align-items-center">
                <div class="image d-flex justify-content-center align-items-center">
                  <img height="50" width="50" :src="item.showimg_url ? item.showimg_url : 'https://ik.imagekit.io/powerbiz/no-image.png?ik-sdk-version=javascript-1.4.3&updatedAt=1603265244531'" alt="img">
                </div>
              </a-col>
              <a-col :span="16" class="d-flex justify-content-start align-items-center">
                <a-row>
                  <a-col :span="24">
                    <div style="color: #141414" class="pbz-font body-md-medium">
                      {{ item.title }}
                    </div>
                  </a-col>
                  <a-col :span="24">
                    <div style="color: #999999" class="pbz-font caption-medium">
                      SKU: {{ item.sku }}
                    </div>
                  </a-col>
                  <a-col :span="24">
                    <div style="color: #999999" class="pbz-font caption-medium">
                      Stock: {{ item.stock || '-' }}
                    </div>
                  </a-col>
                </a-row>
              </a-col>
            </a-row>
          </a-col>

          <a-col v-if="showButtonLoadMore" :span="24" class="d-flex align-items-center justify-content-center mt-5">
            <div class="load-more" @click="handleButtonLoadMore">
              <div v-if="loading">
                <a-icon type="loading" /> Loading
              </div>
              <div v-if="!loading">
                Load More
              </div>
            </div>
          </a-col>
        </a-row>
      </a-col>
    </a-row>

    <a-row class="footer-update-order mt-5">
      <a-col :span="12" class="d-flex justify-content-center align-items-center">
        <div class="cancel pbz-font button-sm-medium" @click.prevent="() => $emit('handleCancel')">
          Batal
        </div>
      </a-col>
      <a-col :span="12" class="d-flex justify-content-center align-items-center">
        <div
          class="pbz-font button-sm-medium d-flex justify-content-center align-items-center"
          :class="{
            ['save']: disabledButtonFooter ? false : true,
            ['disabled-save']: disabledButtonFooter ? true : false,
          }"
          @click.prevent="handleSubmitButtonFooter"
        >
          Tambahkan Item
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import debounce from 'lodash/debounce'
// import SearchIcon from '@/components/Icons/SecureIcon.vue'

export default {
  components: {
    // SearchIcon,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    allProducts: {
      type: Array,
    },
  },
  data () {
    return {
      // products: [],
      loading: false,
      page: 1,
      limit: 20,
      q: null,
      showButtonLoadMore: true,
      disabledButtonFooter: true,
      limit_catalog: 100,
      total_row_catalog: 0,
      products_raw: [],
      products: [],
    }
  },
  watch: {
    products: {
      deep: true,
      immediate: true,
      handler: function (value) {
        let itemChecked = 0

        value.forEach((item) => {
          if (item.item_checked) {
            itemChecked++
          }
        })

        // eslint-disable-next-line
        this.disabledButtonFooter = itemChecked > 0 ? false : true
      },
    },
  },
  mounted () {
    this.fetchAllProducts()
  },
  methods: {
    fetchAllProducts () {
      this.products = this.allProducts
      this.products_raw = this.products
      this.total_row_catalog = this.products.length

      if (this.limit_catalog >= this.total_row_catalog) {
        this.showButtonLoadMore = false
      }
      // this.loading = true
      // this.$store.dispatch('products/GETPRODUCTCATALOG', {
      //   page: this.page,
      //   limit: this.limit,
      //   q: this.q,
      // })
      //   .then(({ data, total_row }) => {
      //     if (this.q) {
      //       this.products = data.map(item => ({
      //         ...item,
      //         showimg_url: item.image_url && Array.isArray(item.image_url) ? item.image_url[0].imagePath || item.image_url[0] : null,
      //         item_checked: false,
      //       }))
      //     } else {
      //       this.products = [...this.products, ...data]

      //       this.products = this.products.map(item => ({
      //         ...item,
      //         showimg_url: item.image_url && Array.isArray(item.image_url) ? item.image_url[0].imagePath || item.image_url[0] : null,
      //         item_checked: item.item_checked ? item.item_checked : false,
      //       }))
      //     }

      //     if (this.products.length >= total_row) {
      //       this.showButtonLoadMore = false
      //     } else {
      //       this.showButtonLoadMore = true
      //     }
      //   })
      //   .catch(err => console.error(err))
      //   .finally(() => {
      //     this.loading = false
      //   })
    },
    handleSearch: debounce(function (e) {
     const q = e.target.value

      this.products = this.products_raw.filter((item) => {
        if (item.title !== null) {
          return item.title.toLowerCase().indexOf(q.toLowerCase()) >= 0
        }
      })

      if (!q.length && this.products_raw.length) {
        this.products = this.products_raw
      } 
    }, 500),
    handleButtonLoadMore () {

      this.limit_catalog = this.limit_catalog + 100
      if (this.limit_catalog >= this.total_row_catalog) {
        this.showButtonLoadMore = false
      }
      // this.page = this.page + 1

      // this.fetchAllProducts()
    },
    handleItemCheckbox(value, id) {
      this.products = this.products.map(item => ({
        ...item,
        item_checked: item.id === id ? value : item.item_checked,
      }))
    },
    handleSubmitButtonFooter() {
      if (this.disabledButtonFooter) {
        // eslint-disable-next-line
        return
      }

      const dataSelected = this.products.filter(item => item.item_checked)
      this.$emit('handleSubmitAddItem', dataSelected)
    },
  },
}
</script>

<style lang="scss">
.show-product {
  height: 25rem !important;
  overflow-y: auto !important;
  scrollbar-color: #E00000 #999999 !important;

  .load-more {
    color: #141414;
    border: 1px solid #E00000;
    border-radius: 3px;
    padding: 10px;
    cursor: pointer;
  }
  .load-more:hover {
    color: #FFFFFF !important;
    background: #E00000 !important;
  }
}
</style>
