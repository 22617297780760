<template>
  <div>
    <a-row class="mt-5 mb-3 d-flex align-items-center">
      <a-col :span="8" class="d-flex justify-content-start align-items-center">
        <div
          style="
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #1A1A1A;
          "
          class="d-flex justify-content-center align-items-center"
        >
          {{ $t('fulfillment.itemProduct') }}
        </div>
      </a-col>

      <a-col v-if="showListItemToUpdate" :span="16" class="d-flex justify-content-end align-items-center">
        <div
          class="d-flex justify-content-center align-items-center"
          style="cursor: pointer"
          @click.prevent="() => (
            showListItem = true,
            showListItemToUpdate = false
          )"
        >
          <arrow-icon class="mx-2" style="color: #E00000" :rotate="'rotate(270)'" />
          <div class="mx-2 pbz-font buttom-md-medium" style="color: #141414">
            Kembali
          </div>
        </div>
      </a-col>
    </a-row>

    <ShowListItemComponent
      v-if="showListItem"
      :products="products"
      @handleItemCheckbox="handleItemCheckbox"
    />

    <a-row v-if="showListItemToUpdate" class="split-shipment-setup">
      <a-form-model ref="ruleForm" :model="formSplitShipment" @submit.prevent="submitButtonSetupSplitShipment">
        <a-col :span="24" class="mt-4">
          <a-row type="flex" justify="space-around">
            <a-col :span="5" class="d-flex justify-content-center align-items-center">
              <a-form-model-item prop="date" :rules="[{ required: true }]">
                <a-date-picker
                  v-model="formSplitShipment.date"
                  :disabled="!showButtonApplySplitShipment"
                />
              </a-form-model-item>
            </a-col>

            <a-col :span="5">
              <a-form-model-item prop="time" :rules="[{ required: true }]">
                <a-time-picker
                  v-model="formSplitShipment.time"
                  :disabled="!showButtonApplySplitShipment"
                />
              </a-form-model-item>
            </a-col>

            <a-col :span="5">
              <a-form-model-item prop="courier" :rules="[{ required: true }]">
                <a-select
                  v-model="formSplitShipment.courier"
                  style="width: 100%"
                  placeholder="Logistik"
                  :disabled="!showButtonApplySplitShipment"
                >
                  <a-select-option v-for="(item) in courier" :key="item.id" :value="item.id">
                    {{ item.courier_name }} - {{ item.service_name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>

            <a-col :span="5">
              <a-form-model-item prop="awb">
                <a-input
                  v-model="formSplitShipment.awb"
                  placeholder="AWB"
                  :disabled="!showButtonApplySplitShipment"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-col>

        <a-col v-if="showButtonApplySplitShipment" :span="24" class="mt-4 d-flex justify-content-end align-items-center">
          <a-form-model-item>
            <a-button
              html-type="submit"
              size="large"
              :style="{
                backgroundColor: '#E00000',
                color: '#FFFFFF'
              }"
            >
              <strong> {{ $t('utils.apply') }} </strong>
            </a-button>
          </a-form-model-item>
        </a-col>

        <a-col v-if="!showButtonApplySplitShipment" :span="24" class="mt-4 d-flex justify-content-end align-items-center">
          <a-button
            html-type="submit"
            size="large"
            :style="{
              backgroundColor: '#E00000',
              color: '#FFFFFF'
            }"
            @click.prevent="
              () => (
                showButtonApplySplitShipment = true,
                disabledButtonFooter = true
              )
            "
          >
            <strong> Edit </strong>
          </a-button>
        </a-col>
      </a-form-model>
    </a-row>

    <a-row v-if="showListItemToUpdate">
      <div v-for="(item, index) in productsToUpdate" :key="index" style="cursor: pointer">
        <a-row class="product-item d-flex align-items-center">
          <a-col :span="4" class="d-flex justify-content-center align-items-center">
            <div class="image d-flex justify-content-center align-items-center">
              <img height="35" width="35" :src="item.img_url ? item.img_url : 'https://ik.imagekit.io/powerbiz/no-image.png?ik-sdk-version=javascript-1.4.3&updatedAt=1603265244531'" alt="img" />
            </div>
          </a-col>

          <a-col :span="4" class="">
            <div class="pbz-font button-sm-medium product-item__title">
              {{ item.catalog_title }}
            </div>
            <div class="pbz-font caption-medium product-item__sku">
              SKU: {{ item.sku }}
            </div>
          </a-col>

          <a-col :span="4" class="d-flex justify-content-center align-items-center flex-column">
            <div class="product-item__sku">
              Qty Terpisah
            </div>
            <a-form-model ref="ruleForm" :model="formSplitShipment" @submit.prevent="submitButtonSetupSplitShipment">
              <a-form-model-item prop="qty" :rules="[{ required: true }]">
                <a-input-number
                  v-model="formSplitShipment.qty"
                  :disabled="!disabledButtonFooter"
                  size="small"
                  :min="1"
                  :max="item.quantity"
                  @change="(value) => handleChangeSplitQty(value, item.id)"
                  @keydown="idRule($event)"
                />
              </a-form-model-item>
            </a-form-model>
          </a-col>

          <a-col :span="6" class="d-flex justify-content-center align-items-center flex-column">
            <div class="product-item__sku">
              Inventori Booking
            </div>
            <div class="product-item__title">
              {{ wordingBookingState(item.booking_state) }}
            </div>
          </a-col>

          <a-col :span="6" class="d-flex justify-content-center align-items-center flex-column">
            <div :style="styleOrderUpdated(item.status_updated)" class="product-item__title">
              {{ item.status_updated }}
            </div>
          </a-col>
        </a-row>
      </div>
    </a-row>

    <a-row class="footer-update-order mt-5">
      <a-col :span="12" class="d-flex justify-content-center align-items-center">
        <div class="cancel pbz-font button-sm-medium" @click.prevent="handleCancelButton">
          Batal
        </div>
      </a-col>
      <a-col :span="12" class="d-flex justify-content-center align-items-center">
        <div
          class="pbz-font button-sm-medium d-flex justify-content-center align-items-center"
          :class="{
            ['save']: disabledButtonFooter ? false : true,
            ['disabled-save']: disabledButtonFooter ? true : false,
          }"
          @click.prevent="handleSubmitButtonFooter"
        >
          Selanjutnya
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import ArrowIcon from '@/components/Icons/Arrow.vue'
import ShowListItemComponent from '../ShowListItem'
import {
  setWordingBookingState,
} from '@/utils/fulfillment/index'

import BASE_URL from '@/services/axios'

export default {
  components: {
    ArrowIcon,
    ShowListItemComponent,
  },
  props: {
    propsProducts: {
      type: Array,
      default: () => [],
    },
    order: {
      type: Object,
    },
    detailFulfillment: {
      type: Object,
      default: () => {},
    },
  },
  data () {
    return {
      products: [],
      productsToUpdate: [],
      showListItem: true,
      showListItemToUpdate: false,
      disabledButtonFooter: true,
      formSplitShipment: {
        date: null,
        time: null,
        courier: '',
        awb: '',
        qty: '',
      },
      showButtonApplySplitShipment: true,
      courier: [],
    }
  },
  watch: {
    propsProducts: {
      immediate: true,
      deep: true,
      handler: function () {
        this.products = this.propsProducts
      },
    },
    products: {
      immediate: true,
      deep: true,
      handler: function (value) {
        let itemChecked = 0

        value.forEach((item) => {
          if (item.item_checked) {
            itemChecked++
          }
        })

        // eslint-disable-next-line
        this.disabledButtonFooter = itemChecked > 0 ? false : true

        this.productsToUpdate = Array.isArray(value) && value.length > 0 ? value.filter(f => f.item_checked).map(item => ({
          ...item,
          status_updated: '',
          show_all_products: false,
          qty_splited: 1,
        })) : []
      },
    },
    showListItemToUpdate: {
      immediate: true,
      deep: true,
      handler: function (value) {
        if (value) {
          this.disabledButtonFooter = true
        } else {
          let itemChecked = 0
          this.products.forEach((item) => {
            if (item.item_checked) {
              itemChecked++
            }
          })
          // eslint-disable-next-line
          this.disabledButtonFooter = itemChecked > 0 ? false : true
        }
      },
    },
  },
  mounted () {
    this.fetchCourier()
  },
  methods: {
    async fetchCourier () {
      try {
        const { data: response } = await BASE_URL({
          method: 'get',
          url: '/shippingv2/api/v1/shipping-methods',
          params: {
            business_id: this.order.business_id,
          },
        })

        this.courier = response.data || []
      } catch (error) {
        this.$notification.error({
          message: error,
        })
      }
    },
    handleItemCheckbox ({ event, id }) {
      this.products = this.products.map(item => ({
        ...item,
        item_checked: item.id === id ? event.target.checked : item.item_checked,
      }))
    },
    handleSubmitButtonFooter () {
      if (this.disabledButtonFooter) {
        // eslint-disable-next-line
        return
      }

      if (this.showListItem) {
        this.showListItem = false
        this.showListItemToUpdate = true
        this.disabledButtonFooter = true

        // eslint-disable-next-line
        return
      }

      if (this.showListItemToUpdate) {
        this.$emit('handleModalConfirmation', true)
      }
    },
    handleChangeSplitQty (value, id) {
      this.productsToUpdate = this.productsToUpdate.map((item) => ({
        ...item,
        qty_splited: item.id === id ? value : item.qty_splited,
      }))
    },
    wordingBookingState: function (value) {
      return setWordingBookingState(value)
    },
    styleOrderUpdated (status) {
      const data = status.toLowerCase()

      const style = {
        boxShadow: '0px 5px 5.3625px rgba(0, 0, 0, 0.055)',
        borderRadius: '33px',
        color: '#FFFFFF',
        padding: '4px 12px',
      }

      switch (data) {
        case 'pengiriman terpisah': {
          return {
            ...style,
            background: '#454655',
          }
        }

        case 'batal': {
          return {
            ...style,
            background: '#E00000',
          }
        }

        default: {
          return {}
        }
      }
    },
    submitButtonSetupSplitShipment () {
      this.$refs.ruleForm.validate(valid => {
        if (!valid) return

        const date = this.formSplitShipment.date.format('YYYY-MM-DD') // get date
        const time = this.formSplitShipment.time.format('hh:mm:ss') // get only hour and minutes

        const getTime = date + ' ' + time // combine date and time

        const parsingToISOtime = this.$moment(getTime).toISOString() // parsing to is

        const courierDetail = this.courier.find(item => item.id === this.formSplitShipment.courier)

        const wrapData = {
          order_id: this.order.order.id,
          order_number: this.order.order.order_number,
          fulfillment_id: this.detailFulfillment.id,
          line_items: [],
          shipping: {
            awb: this.formSplitShipment.awb ? this.formSplitShipment.awb : '',
            courier: courierDetail.courier_name,
            remarks: '',
            service_code: courierDetail.service_code,
            service_name: courierDetail.service_name,
            shipping_date: parsingToISOtime,
          },
        }

        this.productsToUpdate.forEach(item => {
          wrapData.line_items.push({
            id: item.id,
            catalog_id: item.catalog_id,
            quantity: this.formSplitShipment.qty,
            // quantity: item.qty_splited, // qty yang terupdate ? atau qty sebelumnya?
            serial_number: 'string',
            sku: item.sku,
            title: item.catalog_title,
            uom: item.uom,
            img_url: item.img_url,
          })
        })

        this.showButtonApplySplitShipment = false
        this.disabledButtonFooter = false

        this.productsToUpdate = this.productsToUpdate.map((item) => ({
          ...item,
          status_updated: 'Pengiriman Terpisah',
        }))

        this.$emit('setPayloadSplitShipment', wrapData)
      })
    },
    idRule(e) {
      if (/^\D$/.test(e.key)) {
        e.preventDefault()
      }
    },

    handleCancelButton () {

      if (this.showListItemToUpdate) {

        this.showListItem = true

        this.showListItemToUpdate = false

      } else if (!this.showListItemToUpdate) {

        this.$emit('handleCancel')

      }

    },
  },
}
</script>

<style>

</style>
