<template>
  <a-modal
    :visible="true"
    :footer="false"
    :title="null"
    class="confirmation-update-order"
    @cancel="() => $emit('handleModalConfirmation', false)"
  >
    <a-row>
      <a-col :span="24" class="d-flex justify-content-center">
        <div class="pbz-font subtitle-md-medium text-confirmation">
          Konfirmasi
        </div>
      </a-col>

      <a-col :span="24" class="mt-3 d-flex justify-content-center">
        <div v-if="payloadSplitShipment.line_items" class="pbz-font body-md-regular text-caption">
          Apakah anda yakin akan melakukan perubahan ini?
        </div>

        <div v-if="payloadCancelItem.line_items" class="pbz-font body-md-regular text-caption">
          <div v-if="detailFulfillment.line_items.length === 1" class="text-center">
            Pembatalan item ini akan menyebabkan order dibatalkan, Apakah Anda yakin akan batalkan item berikut?
          </div>

          <div v-else>
            Apakah Anda yakin akan batalkan pesanan berikut?
          </div>
        </div>

        <div v-if="payloadUpdateItem.length > 0" class="pbz-font body-md-regular text-caption">
          <div v-if="(udpateItem.qytChange.length > 0) && (udpateItem.lineItemChange.length === 0)">
            Apakah Anda yakin akan ubah kuantitas item pesanan berikut?
          </div>

          <div v-else>
            Apakah Anda yakin akan melakukan perubahan ini?
          </div>
        </div>
      </a-col>

      <!-- section split shipment -->
      <template>
        <a-col v-if="payloadSplitShipment.line_items" :span="24" class="px-4 mt-4">
          <div class="mb-3">
            <div class="mb-3 pbz-font button-sm-medium">
              Pengiriman Terpisah
            </div>
            <a-row>
              <a-col
                v-for="(item, index) in payloadSplitShipment.line_items"
                :key="index"
                :span="24"
                class="mb-2 d-flex justify-content-between align-items-center"
              >
                <div class="d-flex justify-content-center align-items-center">
                  <div class="image d-flex justify-content-center align-items-center">
                    <img height="35" width="35" :src="item.img_url ? item.img_url : 'https://ik.imagekit.io/powerbiz/no-image.png?ik-sdk-version=javascript-1.4.3&updatedAt=1603265244531'" alt="img" />
                  </div>

                  <div class="ml-3 d-flex justify-content-start align-items-center flex-column">
                    <div style="color: #1a1a1a" class="pbz-font button-sm-medium d-flex justify-content-start align-items-center">
                      {{ item.title }}
                    </div>
                    <div style="color: #999999" class="pbz-font caption-medium d-flex justify-content-start align-items-center">
                      SKU: {{ item.sku }}
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-content-center align-items-center flex-column">
                  <div style="color: #999999" class="pbz-font caption-medium">
                    Qty
                  </div>
                  <div style="color: #1a1a1a" class="pbz-font button-sm-medium">
                    {{ item.quantity }}
                  </div>
                </div>
              </a-col>
            </a-row>
          </div>

          <div class="mt-2">
            <div class="mb-1 pbz-font button-sm-medium">
              Pengiriman Awal
            </div>
            <div class="mb-3 pbz-font caption-medium" style="color: #999999">
              ID Pengiriman: {{ detailFulfillment.shipping.id }}
            </div>
            <a-row>
              <a-col
                v-for="(item, index) in products"
                :key="index"
                :span="24"
                class="mb-2 d-flex justify-content-between align-items-center"
              >
                <div class="d-flex justify-content-center align-items-center">
                  <div class="image d-flex justify-content-center align-items-center">
                    <img height="35" width="35" :src="item.img_url ? item.img_url : 'https://ik.imagekit.io/powerbiz/no-image.png?ik-sdk-version=javascript-1.4.3&updatedAt=1603265244531'" alt="img" />
                  </div>

                  <div class="ml-3 d-flex justify-content-start align-items-center flex-column">
                    <div style="color: #1a1a1a" class="pbz-font button-sm-medium d-flex justify-content-start align-items-center">
                      {{ item.title }}
                    </div>
                    <div style="color: #999999" class="pbz-font caption-medium d-flex justify-content-start align-items-center">
                      SKU: {{ item.sku }}
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-content-center align-items-center flex-column">
                  <div style="color: #999999" class="pbz-font caption-medium">
                    Qty
                  </div>
                  <div style="color: #1a1a1a" class="pbz-font button-sm-medium">
                    {{ item.quantity }}
                  </div>
                </div>
              </a-col>
            </a-row>
          </div>
        </a-col>
      </template>

      <!-- section cancel item -->
      <template>
        <a-col v-if="payloadCancelItem.line_items" :span="24" class="px-4 mt-4">
          <div class="mb-3">
            <div class="mb-3 pbz-font button-sm-medium">
              Produk Yang Dibatalkan
            </div>
            <a-row>
              <a-col
                v-for="(item, index) in payloadCancelItem.line_items"
                :key="index"
                :span="24"
                class="mb-2 d-flex justify-content-between align-items-center"
              >
                <div class="d-flex justify-content-center align-items-center">
                  <div class="image d-flex justify-content-center align-items-center">
                    <img height="35" width="35" :src="item.img_url ? item.img_url : 'https://ik.imagekit.io/powerbiz/no-image.png?ik-sdk-version=javascript-1.4.3&updatedAt=1603265244531'" alt="img" />
                  </div>

                  <div class="ml-3 d-flex justify-content-start align-items-center flex-column">
                    <div style="color: #1a1a1a" class="pbz-font button-sm-medium d-flex justify-content-start align-items-center">
                      {{ item.title }}
                    </div>
                    <div style="color: #999999" class="pbz-font caption-medium d-flex justify-content-start align-items-center">
                      SKU: {{ item.sku }}
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-content-center align-items-center flex-column">
                  <div style="color: #999999" class="pbz-font caption-medium">
                    Qty
                  </div>
                  <div style="color: #1a1a1a" class="pbz-font button-sm-medium">
                    {{ item.quantity }}
                  </div>
                </div>
              </a-col>
            </a-row>
          </div>
        </a-col>
      </template>

      <!-- section udpate item -->
      <template>
        <a-col v-if="payloadUpdateItem.length > 0" :span="24" class="px-4 mt-4">
          <div class="mb-3">
            <a-row>
              <a-col
                v-for="(item, index) in payloadUpdateItem"
                :key="index"
                :span="24"
                class="mb-2 d-flex justify-content-between align-items-center"
              >
                <div class="d-flex justify-content-center align-items-center">
                  <div class="image d-flex justify-content-center align-items-center">
                    <img height="35" width="35" :src="item.img_url ? item.img_url : 'https://ik.imagekit.io/powerbiz/no-image.png?ik-sdk-version=javascript-1.4.3&updatedAt=1603265244531'" alt="img" />
                  </div>

                  <div class="ml-3 d-flex justify-content-start align-items-center flex-column">
                    <div style="color: #1a1a1a" class="pbz-font button-sm-medium d-flex justify-content-start align-items-center">
                      {{ item.catalog_title }}
                    </div>
                    <div style="color: #999999" class="pbz-font caption-medium d-flex justify-content-start align-items-center">
                      SKU: {{ item.sku }}
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-content-center align-items-center flex-column">
                  <template v-if="!isQtyChange(item.catalog_id, item.quantity)">
                    <div style="color: #999999" class="pbz-font caption-medium">
                      Qty
                    </div>
                    <div style="color: #1a1a1a" class="pbz-font button-sm-medium">
                      {{ item.quantity }}
                    </div>
                  </template>

                  <template v-if="isQtyChange(item.catalog_id, item.quantity)">
                    <div style="color: #999999" class="pbz-font caption-medium">
                      Qty
                    </div>
                    <div style="color: #1a1a1a" class="pbz-font button-sm-medium">
                      {{ getQtyBefore(item.catalog_id) }} <a-icon type="arrow-right" /> {{ item.quantity }}
                    </div>
                  </template>
                </div>
              </a-col>
            </a-row>
          </div>
        </a-col>
      </template>

      <a-col :span="24" class="mt-5 d-flex justify-content-center align-items-center">
        <a-button class="save pbz-font button-sm-medium d-flex justify-content-center align-items-center" @click.prevent="submitUpdate">
          <div v-if="!loading && payloadSplitShipment.line_items">
            Simpan
          </div>

          <div v-if="!loading && payloadCancelItem.line_items">
            Ya, Batalkan Item
          </div>

          <div v-if="!loading && (payloadUpdateItem.length > 0)">
            Ya, Ubah Kuantitas
          </div>

          <div v-if="loading">
            <a-icon type="loading" />
          </div>
        </a-button>
      </a-col>

      <a-col :span="24" class="d-flex justify-content-center align-items-center mt-2">
        <a-button class="cancel pbz-font button-sm-medium d-flex justify-content-center" @click.prevent="() => $emit('handleModalConfirmation', false)">
          Batal
        </a-button>
      </a-col>
    </a-row>
  </a-modal>
</template>

<script>
import { splitShipment, changeQty, fulfillmentCancelItem } from '@/api/fulfillment'
import { urlToDetailFulfillment } from '@/utils/fulfillment/index'
import getQueryParam from '@/utils/getQueryParam'

export default {
  mixins: [getQueryParam],
  props: {
    payloadSplitShipment: {
      type: Object,
      default: () => ({} || null),
    },
    payloadCancelItem: {
      type: Object,
      default: () => ({} || null),
    },
    detailFulfillment: {
      type: Object,
    },
    products: {
      type: Array,
      default: () => [],
    },
    payloadUpdateItem: {
      type: Array,
      default: () => [],
    },
    order: {
      type: Object,
    },
  },
  data () {
    return {
      loading: false,
      udpateItem: {
        qytChange: [],
        lineItemChange: [],
      },
    }
  },
  computed: {
    getUrlToDetailFulfillment: function () {
      return urlToDetailFulfillment({
        meta_key_url: this.$route.meta.key,
        id: this.$route.params.id,
        channel_type: this.$route.params.channelType,
      })
    },
  },
  watch: {
    payloadUpdateItem: {
      deep: true,
      immediate: true,
      handler: function (value) {
        value.forEach(item => {
          if (item.is_line_item_change && (item.is_quantity_change || !item.is_quantity_change)) {
            const payload = item
            delete payload.is_line_item_change
            delete payload.is_quantity_change
            this.udpateItem.lineItemChange.push(payload)
          } else if (item.is_quantity_change) {
            const payload = item
            delete payload.is_line_item_change
            delete payload.is_quantity_change
            this.udpateItem.qytChange.push(payload)
          }
        })
      },
    },
  },
  methods: {
    submitUpdate () {
      const promises = []

      if (this.payloadSplitShipment.line_items) {
        promises.push(
          splitShipment({
            url: 'fulfillment',
            business_id: this.order.business_id,
            data: this.payloadSplitShipment, 
          }),
        )
      }

      if (this.payloadCancelItem.line_items && (this.products.length > 1)) {
        const payload = {
          url: 'fulfillment',
          fulfillment_id: this.$route.query.fulfillment_id_to_update,
          data: { item_id: this.payloadCancelItem.line_items.map(item => item.id) },
        }

        promises.push(
          fulfillmentCancelItem(payload),
        )
      } else if (this.payloadCancelItem.line_items && (this.products.length = 1)) {
        this.$emit('handleModalConfirmation', false)
        this.$emit('handleCancelOneItem', true)

        // eslint-disable-next-line
        return
      }

      if (this.payloadUpdateItem.length > 0) {
        const payload = {
          url: 'fulfillment',
          fulfillment_id: this.$route.query.fulfillment_id_to_update,
          data: { items: this.payloadUpdateItem },
        }

        promises.push(
          changeQty(payload),
        )
      }

      this.loading = true

      Promise.all(promises)
        .then(() => {
          setTimeout(() => {
            this.loading = false
            this.$notification.success({
              message: 'Success update order',
            })
            this.$emit('handleModalConfirmation', false)
            this.$router.push({
              path: this.getUrlToDetailFulfillment,
              query: {
                ...this.getQueryParam(this.$route.query),
                fulfillment_id: this.$route.query.fulfillment_id,
              },
            })
          }, 3000)
        })
        .catch(err => {
          this.loading = false
          if (err.response.status === 403) {
            this.$notification.error({
              message: 'Anda tidak memiliki akses untuk fitur ini',
            })
          } else {
            this.$notification.error({
              message: err.response?.data?.message,
            })
          }
        })
    },
    isQtyChange (catalog_id, quantity) {
      const product = this.products.find(item => item.catalog_id === catalog_id)

      return product && product.quantity !== quantity
    },
    getQtyBefore(catalog_id) {
      const product = this.products.find(item => item.catalog_id === catalog_id)

      return product.quantity
    },
  },
}
</script>

<style lang="scss">
.confirmation-update-order {
  .text-confirmation {
    color: #1A1A1A
  }

  .text-caption {
    color: #999999
  }

  .cancel {
    width: 100%;
    height: 100%;
    color: #E00000;
    padding: .75rem;
    cursor: pointer;
    border: 1px solid #E00000;
    border-radius: 5px;
  }

  .save {
    border: 1px solid #E00000;
    background: #E00000;
    color: #FFFFFF;
    width: 100%;
    height: 100%;
    padding: .75rem;
    border-radius: 5px;
    cursor: pointer;
  }
}
</style>
